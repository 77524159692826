(function () {
  window.addEventListener('load', (event) => {
    function getQueryVariable(variable) {
      const query = window.location.search.substring(1);
      const vars = query.split('&');
      for (let i = 0; i < vars.length; i++) {
        const pair = vars[i].split('=');
        if (pair[0] == variable) {
          return pair[1];
        }
      }
      return null;
    }

    // Remediation Onfido
    const onfidoJsNewVer = 'https://cdn.jsdelivr.net/npm/onfido-sdk-ui@6.3.0/dist/onfido.min.js';
    const onfidoCssNewVer = 'https://cdn.jsdelivr.net/npm/onfido-sdk-ui@6.3.0/dist/style.min.css';

    // Aquisition Onfido
    const onfidoJsOldVer = 'https://cdn.jsdelivr.net/npm/onfido-sdk-ui@5.4.0/dist/onfido.min.js';
    const onfidoCssOldVer = 'https://cdn.jsdelivr.net/npm/onfido-sdk-ui@5.4.0/dist/style.min.css';

    const flowParam = getQueryVariable('flow');
    const onfidoParam = getQueryVariable('onfido_new');

    const script = document.createElement('script');
    script.type = 'text/javascript';
    const link = document.createElement('link');
    link.rel = 'stylesheet';

    if (onfidoParam) {
      script.src = flowParam !== null && flowParam === 'amex-nl-b2c-acquisition' ? onfidoJsNewVer : onfidoJsOldVer;
      link.href = flowParam !== null && flowParam === 'amex-nl-b2c-acquisition' ? onfidoCssNewVer : onfidoCssOldVer;
    } else {
      script.src = onfidoJsOldVer;
      link.href = onfidoCssOldVer;
    }

    document.body.appendChild(script);
    document.head.prepend(link);
  });
})();
